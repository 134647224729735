import React from 'react';
import {
  Checkbox,
  InputFooter,
  SelectBox,
  SelectOption,
  TextArea,
  TextField,
} from '@sbt-web/ui';
import type { AdItem } from '@sbt-web/network/types';
import cn from 'classnames';
import classes from '@client/components/Detail/AdAbuse/components/Form/index.module.scss';
import Labels from '@client/components/Detail/AdAbuse/Labels';
import { FormValidation } from '@client/components/Detail/AdAbuse/AdAbuse';

interface Props {
  item: AdItem;
  setSelectValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  setTextAreaValue: React.Dispatch<React.SetStateAction<string>>;
  setClauseValue: React.Dispatch<React.SetStateAction<boolean>>;
  setEmailValue: React.Dispatch<React.SetStateAction<string>>;
  formValidation: FormValidation;
  textAreaValue: string;
  selectValue: string | undefined;
  clauseValue: boolean;
  emailValue: string;
  /**
   * Whether or not the user is anonymous:
   * if they are, they have no user_data cookie and so we ask for their email.
   * If they are logged in, we send the cookie directly instead.
   */
  isAnonymous: boolean;
}

export const AdAbuseForm = function (props: Props) {
  const {
    item,
    formValidation,
    setClauseValue,
    setTextAreaValue,
    setSelectValue,
    setEmailValue,
    textAreaValue,
    selectValue,
    clauseValue,
    emailValue,
    isAnonymous,
  } = props;

  const PRIVACY_URL =
    'https://info.subito.it/policies/informativa-privacy-segnalazione-dsa.htm';

  return (
    <div className={cn(classes['ad-abuse-form'])}>
      <form className={classes['form']} title="Form segnala annuncio">
        <input type="hidden" name="ad_urn" value={item.urn} />
        <div className={classes['row-container']}>
          <SelectBox
            name="reason"
            flavor="small"
            valueList={Labels.FORM.select.abuseReasons as SelectOption[]}
            onSelect={(o: SelectOption[]) => setSelectValue(o[0]?.value)}
            selectedIndices={
              selectValue
                ? [
                    Labels.FORM.select.abuseReasons.findIndex(
                      (obj) => obj.value === selectValue
                    ),
                  ]
                : undefined
            }
            errorMessage={
              !formValidation.isReasonValid
                ? Labels.FORM.select.error
                : undefined
            }
            placeholder={Labels.FORM.select.placeholder}
            nullable={true}
            inputHeaderProps={{
              label: Labels.FORM.select.label,
            }}
          />
        </div>
        <div
          className={cn(classes['row-container'], classes['text-area-wrapper'])}
        >
          <TextArea
            rows={4}
            inputHeaderProps={{ label: Labels.FORM.textArea.label }}
            name="description"
            placeholder={Labels.FORM.textArea.placeholder}
            maxLength={2000}
            value={textAreaValue}
            errorMessage={
              !formValidation.isDescriptionValid
                ? Labels.FORM.textArea.error
                : undefined
            }
            onInputChange={(e) => setTextAreaValue(e.target.value)}
          />
        </div>
        {isAnonymous ? (
          <div className={cn(classes['row-container'])}>
            <TextField
              name="email"
              placeholder="Indirizzo email"
              inputHeaderProps={{
                label: Labels.FORM.email.label,
              }}
              value={emailValue}
              onInputChange={(e) => {
                setEmailValue(e.target.value);
              }}
              errorMessage={
                !formValidation.isEmailValid
                  ? Labels.FORM.email.error
                  : undefined
              }
              type="email"
            />
          </div>
        ) : null}
        <div className={cn(classes['row-container'])}>
          <Checkbox
            name="clause"
            checked={clauseValue}
            onChange={setClauseValue}
          >
            <>
              Dichiaro di aver preso visione dell&apos;
              <a href={PRIVACY_URL} className={classes['privacy-link']}>
                informativa per il trattamento dei dati personali
              </a>
            </>
          </Checkbox>
          {!formValidation.isClauseValid && (
            <InputFooter message={Labels.FORM.clause.error} />
          )}
        </div>
      </form>
    </div>
  );
};
